<template>
  <div class="health">
    <!-- head -->
    <div class="head">
      <Navigator />
      <h3>消费品行业</h3>
      <p>Consumer goods industry</p>
    </div>
    <!-- head -->
    <!-- content -->
    <Content :data="content" />
    <!-- content -->
    <!-- Footer -->
    <Footer />
    <!-- Footer -->
  </div>
</template>
<script>
export default {
  components: {
    Content: () => import("./component/Content"),
  },
  data() {
    return {
      content: [
        {
          imgSrc: require("@/assets/img/health1.png"),
          title: "消费品RFID防伪溯源系统",
          text: "以RFID电子标签作为防伪溯源信息载体，是集成RFID技术、计算机网络技术、数字通信技术、数据库技术、软件工程技术于一体的大型信息系统。该系统除了应用茶叶外，也可广泛应就于保健品、酒类等消费品领域的防伪溯源，以及高附加值的贵重物品的防伪溯源。防伪溯源系统不仅可以对假冒伪劣现象进行有效抵制，还可以提高企业生产、物流的效率，提升企业整体管理水平，从而增强消费者信心，提高产品的知名度，树立产品品牌。利用RFID技术防伪溯源，与传统防伪技术相比，其优点在于每个标签具有全球唯一的ID号码，号码直接写入芯片，无法修改、难以仿造，无机械磨损，防污损。",
          careArr: [
            "实现产品的防伪",
            "实现销售流通的追溯",
            "为产品品牌赋能",
            "有效连接消费者与品牌方",
          ],
          iconArr: [
            require("@/assets/img/icon12.png"),
            require("@/assets/img/icon13.png"),
          ],
        },
        {
          imgSrc: require("@/assets/img/国肽.jpg"),
          title: "一物一码防窜货管理系统",
          text: "一物一码防窜货解决方案，根据产品的物流转改，对产品的物流信息统计采集，确保产品追踪的准确性和灵活性。实时物流跟踪，将所有产品的流动信息和经销商的情况掌握在手里。对产品信息进行采集，让企业知道每件产品的去向。实施防窜货管理流程，构建企业数字化运营管理体系。严格的规范货物出货地点，避免了窜货的出现。有了防窜货系统之后，可以追究是谁的责任，也就能够治理窜货现象。",
          careArr: [
            "商品追踪：总部、经销商、零售点通过扫码查询产品溯源和流向",
            "多层级出货：支持多级经销商自行设置下级经销商",
            "风险提示：通过数据自动分析运算，提供窜货风险",
            "可视化报表：各级的收发货、库存、效期等数据一目了然",
          ],
          iconArr: [require("@/assets/img/icon12.png")],
        },
        {
          imgSrc: require("@/assets/img/铺货管理系统.jpg"),
          title: "铺货管理系统",
          text: "快消品主要特点在于保质期短，周转快，为了能够在及时订货、补货，而且要经常关注产品效期，在没有使用系统之前，业务员和门店需要每天根据销售情况手动记账和线下电话联络订货和配送，整个流程下来效率低，时间长。秀圆果铺货管理系统，为店主线上订货、业务员配送提供了一套便捷手段，并将数据汇总到平台，为各地区、各级销售公司提供供需数据报表的一体化的解决方案。",
          careArr: [
            "店主线上订货一键通达",
            "业务员配货一键登记",
            "RFID巡店打卡确保到店真实",
            "区域联网随时掌握销售数据",
          ],
          iconArr: [require("@/assets/img/icon15.png")],
        },
        {
          imgSrc: require("@/assets/img/导购促销系统.jpg"),
          title: "导购促销系统",
          text: "导购促销系统是一种基于NFC或二维码终端营销方案，通过信息系统支持，在零售时通过NFC或二维码记录所售产品，以实物不可篡改的数据为绩效依据，通过提供精神和物质的奖励的方式来促进产品销售，为总部、各零售商、促销员和消费者之间建立一种良性的合作共赢机制。",
          careArr: [
            "导购员促销激励出货",
            "提高产品销量和门店上架率",
            "提高市场营销投放度和投入产出比",
            "自动佣金核算，促销费用直达终端",
          ],
          iconArr: [require("@/assets/img/icon12.png")],
        },
        {
          imgSrc: require("@/assets/img/可视化数据大屏系统.png"),
          title: "数据可视化大屏系统",
          text: "通过系统的产品销售的各个环节被记录下来，企业拥有商品流通数据、客户消费数据、客户行为数据、产品销售数据等多个维度的数据。应用大数据分析技术，销售企业可以找到商品和客户之间的关联关系、商品之间的关联关系，也就找到了销售密码。销售数据可视化提供对销售情况的多个视角展示销售状况，分析各个区域、各个类别的销售业绩情况。并且通过对于产品销量排行，快速的查看出销量好的产品，为决策提供支持。",
          careArr: [
            "支持多种仪表盘配置，支持定制展示",
            "动态、实时展示，经营战况一目了然",
            "总部、区域、销售点数据分层按角色展示",
            "支持与企业自有系统数据对接",
          ],
          iconArr: [require("@/assets/img/icon15.png")],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .health {
    position: relative;
    height: 100%;
    .head {
      height: 8.19rem;
      width: 100%;
      overflow: hidden;
      background: url(../../../assets/img/society-bj.png) no-repeat;
      background-size: 100% 100%;
      h3 {
        font-size: 1.02rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.18rem;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        margin-top: 4.45rem;
        margin-left: 7.1rem;
      }
      p {
        font-size: 0.41rem;
        font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
        // margin-left: 8.38rem;
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .health {
    position: relative;
    height: 100%;
    .head {
      height: 5.48rem;
      width: 19.2rem;
      overflow: hidden;
      background: url(../../../assets/img/health0.png) no-repeat;
      background-size: 100% 100%;
      h3 {
        // width: 4.5rem;
        height: 1.4rem;
        font-size: 1rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #fff;
        line-height: 1.17rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        margin-top: 2.23rem;
        text-align: center;
      }
      p {
        height: 0.56rem;
        font-size: 0.4rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 0.47rem;
        text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
        // margin-left: 8.2rem;
        text-align: center;
      }
    }
  }
}
</style>
